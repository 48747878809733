.container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  
  .navbar {
    max-width: 1300px;
    margin: 0 auto 10px;
    height: 80px;
    background-color: #dad7cd;
    position: relative;
  }
  
  .navbar-logo {
    width: 200px;
    height: auto;
  }
  
  .menu-icon {
    display: none;
  }
  
  .nav-elements {
  }
  
  .nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
  }
  
  .nav-elements ul li:not(:last-child) {
    margin-right: 60px;
  }
  
  .nav-elements ul a {
    font-size: 16px;
    font-weight: 500;
    color: #a3b18a;
    text-decoration: none;
  }
  
  .nav-elements ul a.active {
    color: #11542A;
    font-weight: 600;
    position: relative;
  }
  
  .nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
  }
  
  @media (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
      margin-right: 30px;
    }
  }
  
  @media (max-width: 600px) {
    .menu-icon {
      display: block;
      cursor: pointer;
    }
  
    .nav-elements {
      position: absolute;
      right: 0;
      top: 60px;
      background-color: #dad7cd;
      z-index: 5;
      width: 0px;
      height: calc(100vh - 60px);
      transition: all 0.3s ease-in;
      overflow: hidden;
    }
  
    .nav-elements.active {
      width: 270px;
    }
  
    .nav-elements ul {
      display: flex;
      flex-direction: column;
    }
  
    .nav-elements ul li {
      margin-right: unset;
      margin-top: 22px;
    }
  }
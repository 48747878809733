@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

/* 
#11542A 17,84,42 
#588157  88,129,87
#a3b18a  163, 177, 138
#dad7cd  218, 215, 205
#8a8889 grey text
*/

.App{
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
}